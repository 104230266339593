import React, {Component} from 'react';

class SessionHelper extends Component {
    static SetBranchesSession(data){
        localStorage.setItem('branches', JSON.stringify(data));
    }

    static GetBranchesSession() {
        const branches = localStorage.getItem('branches');
        return branches && branches !== "undefined" ? JSON.parse(branches) : null;
    }
}

export default SessionHelper;