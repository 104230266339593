import React, { Component } from 'react';
import axios from "axios";
import OwlCarousel from 'react-owl-carousel';
import useBranches from '../data/useBranches';
const options = {
    loop: false,
    margin: 0,
    nav: true,
    autoWidth:true,
    items:2,
    navContainer: false,
    navElement: "div",
    dots: false,
};



const FilterSection  = () => {
    const branches = useBranches();

        return (
            <>
                <section className="filter_section_wrapper">
                    <div className="container-fluid mb-3">
                        <div className="col-sm-11 mx-auto">
                            <div className="row">
                                <div className="col-md-12 ">
                                    <OwlCarousel  className="icon-menu filter-carousel owl-carousel owl-theme" {...options}>
                                        <div className="item">
                                            <a href="javascripts:void()">
                                                <img src="/assets/images/7969735.png" alt=" All Rooms" />
                                                All Rooms
                                            </a>
                                        </div>
                                        {branches.map((branch, index) => (
                                            <div className="item" key={index}>
                                                <a href="javascripts:void()">
                                                    <img 
                                                        src={branch.thumbnail.data_url || "/assets/images/7969735.png"} 
                                                        alt={branch.name} 
                                                    />
                                                    {branch.name}
                                                </a>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        );
}

export default FilterSection;
