import React, { useState } from 'react';
import { DatePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

const SingleDatePicker = () => {
    const [date, setDate] = useState(null);

    const handleDateChange = (value) => {
        setDate(value);
        console.log("Check In:", value);
    };

    return (
        <>
            <DatePicker 
                placeholder="Check In" 
                className="search_date_select" 
                value={date} 
                onChange={handleDateChange} 
            />
        </>
    );
};

export default SingleDatePicker;
