import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';

const suggestions = [
    { name: "Ashulia Male Branch", content: "<p><strong>Ashulia Male Branch</strong></p><p>Khagan Bazar, Birulia, Ashulia</p>" },
    { name: "Dhanmondhi Female Branch", content: "<p><strong>Dhanmondhi Female Branch</strong></p><p>251/L, House# 23, Road# 13/A, Dhanmondi, Dhaka</p>" },
    { name: "Uttara Female Branch", content: "<p><strong>Uttara Female Branch</strong></p><p>House# 20, Road# 05, Sector# 05, Uttara Branch, Dhaka</p>" },
];

// Custom rendering for suggestions
const renderSuggestion = (suggestion) => (
    <div dangerouslySetInnerHTML={{ __html: suggestion.content }} />
);

const getSuggestionValue = (suggestion) => suggestion.name;

const AutosuggestInput = () => {
    const [searchValue, setSearchValue] = useState("");  // Using `searchValue` and `setSearchValue`
    const [suggestedItems, setSuggestedItems] = useState([]);

    const onSuggestionsFetchRequested = ({ value }) => {
        const filteredSuggestions = suggestions.filter(suggestion =>
            suggestion.name.toLowerCase().includes(value.toLowerCase())
        );
        setSuggestedItems(filteredSuggestions);
    };

    const onSuggestionsClearRequested = () => {
        setSuggestedItems([]);
    };

    return (
        <Autosuggest
            suggestions={suggestedItems}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={{
                placeholder: "Search here....",
                value: searchValue,  // Updated to use `searchValue`
                onChange: (_, { newValue }) => setSearchValue(newValue)  // Updated to use `setSearchValue`
            }}
        />
    );
};

export default AutosuggestInput;