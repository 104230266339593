import { useState, useEffect } from 'react';
import axios from 'axios';
import SessionHelper from '../../session/SessionHelper';

function useBranches() {
    const [branches, setBranches] = useState(() => {
        const savedBranches = SessionHelper.GetBranchesSession();
        return savedBranches ?? [];
    });

    useEffect(() => {
        if (!SessionHelper.GetBranchesSession()) {
            axios.get('/sanctum/csrf-cookie').then(() => {
                axios.get('/api/branches').then((res) => {
                    const branchData = res.data.data;
                    setBranches(branchData);
                    SessionHelper.SetBranchesSession(branchData);
                }).catch((error) => {
                    console.error("Error fetching branches:", error);
                });
            });
        }
    }, []);

    return branches;
}

export default useBranches;
