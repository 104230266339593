import React, { useState, useRef, useEffect } from 'react';
import AutosuggestInput from './searchForm/AutosuggestInput';
import SingleDatePicker from './searchForm/SingleDatePicker';
import DateRangeSelector from './searchForm/DateRangeSelector';

const SearchForm = () => {
    const [adultCount, setAdultCount] = useState(0);
    const [childrenCount, setChildrenCount] = useState(0);
    const [roomCount, setRoomCount] = useState(1);
    const [dateRange, setDateRange] = useState([null, null]);

    const increment = (setter, value) => setter(value + 1);
    const decrement = (setter, value) => setter(value > 0 ? value - 1 : 0);

    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const overlayRef = useRef(null);
    const toggleButtonRef = useRef(null);
    const toggleOverlay = () => {
        setIsOverlayOpen((prevState) => !prevState);
    };

    const handleClickOutside = (event) => {
        if (
            overlayRef.current &&
            !overlayRef.current.contains(event.target) &&
            !toggleButtonRef.current.contains(event.target)
        ) {
            setIsOverlayOpen(false);
        }
    };

    const getPlaceholderText = () => {
        if (adultCount > 0 || childrenCount > 0 || roomCount > 0) {
            return [
                adultCount > 0 ? `${adultCount} Adult${adultCount > 1 ? 's' : ''}` : '',
                childrenCount > 0 ? `${childrenCount} Child${childrenCount > 1 ? 'ren' : ''}` : '',
                roomCount > 0 ? `${roomCount} Room${roomCount > 1 ? 's' : ''}` : ''
            ].filter(Boolean).join(', ');
        }
        return "Add Guest";
    };

    useEffect(() => {
        if (isOverlayOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOverlayOpen]);

    return (
        <>
            <div className="container mb-4">
                <div className="search_form">
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="permanent" role="tabpanel"
                            aria-labelledby="permanent-tab">
                            <div className="d-flex align-items-center">
                                <div className="flex-fill  pl-0 autosuggest_search_box">
                                    <AutosuggestInput />
                                </div>
                                <div className="border-left mx-2" style={{ height: "30px" }} ></div>
                                <div className="flex-fill pl-0">
                                    <SingleDatePicker />
                                </div>
                                <div className="search_submit_btn">
                                    <button className="btn btn-danger rounded-circle p-2" type="submit">
                                        <i className="fa fa-search text-white"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="guest" role="tabpanel" aria-labelledby="guest-tab">
                            <div className="d-flex align-items-center">
                                <div className="flex-fill  pl-0 autosuggest_search_box">
                                    <AutosuggestInput />
                                </div>
                                <div className="border-left mx-2" style={{ height: "30px" }}></div>
                                <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />
                                <div className="border-left mx-2" style={{ height: "30px" }}></div>
                                <div className="flex-fill" style={{ 'position': 'relative' }}>
                                    <input type="text"
                                        className="form-control border-0 p-0 pr-5"
                                        placeholder={getPlaceholderText()}
                                        value={getPlaceholderText()}
                                        ref={toggleButtonRef}
                                        onClick={toggleOverlay}
                                    />
                                    <div ref={overlayRef} className={`input_overlay ${isOverlayOpen ? 'open' : ''}`}>
                                        <div className='guest_counter'>
                                            <div className='pull-left'>
                                                <strong>Adults</strong>
                                                <br />
                                                <span>Age 13+</span>
                                            </div>
                                            <div className='input_counter pull-right'>
                                                <button onClick={() => decrement(setAdultCount, adultCount)}>-</button>
                                                <strong className='counter'>{adultCount}</strong>
                                                <button onClick={() => increment(setAdultCount, adultCount)}>+</button>
                                            </div>
                                        </div>
                                        <div className='guest_counter'>
                                            <div className='pull-left'>
                                                <strong>Children</strong>
                                                <br />
                                                <span>Age 0-5</span>
                                            </div>
                                            <div className='input_counter pull-right'>
                                                <button onClick={() => decrement(setChildrenCount, childrenCount)}>-</button>
                                                <strong className='counter'>{childrenCount}</strong>
                                                <button onClick={() => increment(setChildrenCount, childrenCount)}>+</button>
                                            </div>
                                        </div>
                                        <div className='guest_counter mb-0'>
                                            <div className='pull-left'>
                                                <strong>Room</strong>
                                                <br />
                                                <span>Minimum 1+</span>
                                            </div>
                                            <div className='input_counter pull-right'>
                                                <button onClick={() => decrement(setRoomCount, roomCount)}>-</button>
                                                <strong className='counter'>{roomCount}</strong>
                                                <button onClick={() => increment(setRoomCount, roomCount)}>+</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="search_submit_btn">
                                    <button className="btn btn-danger rounded-circle p-2" type="submit">
                                        <i className="fa fa-search text-white"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchForm;
