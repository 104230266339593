import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import HtmlParser from 'html-react-parser';
import WithRouter from '../../_utility/WithRouter';
import { useNavigate } from 'react-router-dom';

import 'rsuite/dist/rsuite.min.css';
import { DateRangePicker, DatePicker } from 'rsuite';

import OwlCarousel from 'react-owl-carousel';
const options = {
    items: 1,
    margin: 20,
    itemsDesktop: [1000, 1],
    itemsDesktopSmall: [979, 1],
    itemsTablet: [768, 1],
    pagination: false,
    navigation: false,
    dots: false,
    navigationText: ["", ""],
    slideSpeed: 1000,
    singleItem: true,
    loop: true,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true
};

const RoomDetails = (props) => {
    const [loading, setLoading] = useState(true);
    const [room, setRoom] = useState([]);
    const [images, setImages] = useState([]);
    const [description, setDescription] = useState('');

    const [adultCount, setAdultCount] = useState(0);
    const [childrenCount, setChildrenCount] = useState(0);
    const [roomCount, setRoomCount] = useState(1);

    const increment = (setter, value) => setter(value + 1);
    const decrement = (setter, value) => setter(value > 0 ? value - 1 : 0);

    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const overlayRef = useRef(null);
    const toggleButtonRef = useRef(null);
    const toggleOverlay = () => {
        setIsOverlayOpen((prevState) => !prevState);
    };

    const handleClickOutside = (event) => {
        if (
            overlayRef.current &&
            !overlayRef.current.contains(event.target) &&
            !toggleButtonRef.current.contains(event.target)
        ) {
            setIsOverlayOpen(false);
        }
    };

    useEffect(() => {
        if (isOverlayOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOverlayOpen]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const room_id = props.params.room_id;

        axios.get('/sanctum/csrf-cookie').then(() => {
            axios.get(`/api/room/${room_id}/details`)
                .then(res => {
                    const data = res.data.data;
                    setRoom(data);
                    setImages(data.images);
                    setDescription(data.description);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error fetching rooms:", error);
                });
        });
    }, [props.params.room_id]);

    const [dateRange, setDateRange] = useState([null, null]);
    const [open, setOpen] = useState(false);
    const pickerRef = useRef(null);

    const togglePicker = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleDateChange = (value) => {
        setDateRange(value);
        setOpen(false); // Close the picker after selecting dates
    };

    const navigate = useNavigate();
    const handleReserveClick = () => {
        const room_id = props.params.room_id; // Replace this with the actual room ID
        navigate(`/room/${room_id}/booking`);
    };
    return (
        <>
            <section>
                <div className="container-fluid pad-bot-40 mt-5">
                    <div className="col-sm-11 mx-auto">
                        <div className="to-ho-hotel">
                            <div className="row">
                                <div className='col-sm-12'>
                                    <div className="modal fade" id="galeryModal" tabindex="-1" role="dialog" aria-labelledby="galeryModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="galeryModalLabel">All Photos</h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    {room.featured_image && room.featured_image.length > 0 ? (
                                                        <img
                                                            src={room.featured_image[0].data_url}
                                                            alt={room.title}
                                                            className="img-fluid"
                                                        />
                                                    ) : (<></>)}
                                                    {images && images.length > 0 ? (
                                                        images.map((image, index) => (
                                                            <img
                                                                src={image.data_url}
                                                                alt={room.title}
                                                                className="img-fluid"
                                                            />
                                                        ))
                                                    ) : (<></>)}
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row gallery_images_wrap">
                                        <div className="col-md-6 room_gallery_large p-0">
                                            {room.featured_image && room.featured_image.length > 0 ? (
                                                <img
                                                    src={room.featured_image[0].data_url}
                                                    alt={room.title}
                                                    className='d-none d-lg-block d-md-block'
                                                />
                                            ) : (
                                                <img src="/assets/images/room/4.jpg" className='d-none d-lg-block d-md-block' alt="Gallery 2" />
                                            )}
                                        </div>
                                        <div className="col-md-6 room_gallery_small p-0">
                                            {images && images.length > 0 ? (
                                                images.map((image, index) => (
                                                    <img
                                                        src={image.data_url}
                                                        alt={room.title}
                                                    />
                                                ))
                                            ) : (
                                                <>
                                                    <img src="/assets/images/room/4.jpg" alt="Gallery 2" />
                                                    <img src="/assets/images/room/4.jpg" alt="Gallery 2" />
                                                    <img src="/assets/images/room/4.jpg" alt="Gallery 2" />
                                                    <img src="/assets/images/room/4.jpg" alt="Gallery 2" />
                                                </>
                                            )}
                                            <a href='javascripts:void' className='btn btn-light border border-dark gallery-grid-btn' data-toggle="modal" data-target="#galeryModal"><i className="fa fa-th" aria-hidden="true"></i> Show all photos</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="hom-com container pt-5 pb-1">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className='form-group mb-5'>
                                            <h2>{room.title}</h2>
                                            <span>{room.room_type} . {room.max_occupancy} Bed . Free Internet</span>
                                        </div>

                                        <OwlCarousel className="owl-carousel mb-5 owl-theme" {...options}>
                                            <div className="media customer_review">
                                                <img src="//c2.staticflickr.com/8/7310/buddyicons/24846422@N06_r.jpg" className="align-self-center  mr-3" alt="..." />
                                                <div className="media-body">
                                                    <h5 className="mt-0">Gabriel Elijah</h5>
                                                    <div className='review_rating mb-1'>
                                                        <span>4.9 </span>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star-half-o" aria-hidden="true"></i>
                                                    </div>
                                                    <p>Too much good experience with hospitality, cleanliness, facility and privacy and good value for money... To keep mind relaxing... Keep it up...</p>
                                                </div>
                                            </div>
                                            <div className="media customer_review">
                                                <img src="https://i.postimg.cc/ydBjdm20/michael-dam-m-EZ3-Po-FGs-k-unsplash-1.jpg" className="align-self-center  mr-3" alt="..." />
                                                <div className="media-body">
                                                    <h5 className="mt-0">Diana</h5>
                                                    <div className='review_rating mb-1'>
                                                        <span>4.9 </span>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star-half-o" aria-hidden="true"></i>
                                                    </div>
                                                    <p>Too much good experience with hospitality, cleanliness, facility and privacy and good value for money... To keep mind relaxing... Keep it up...</p>
                                                </div>
                                            </div>
                                            <div className="media customer_review">
                                                <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/451270/profile/profile-80.jpg" className="align-self-center  mr-3" alt="..." />
                                                <div className="media-body">
                                                    <h5 className="mt-0">Kevin Jack</h5>
                                                    <div className='review_rating mb-1'>
                                                        <span>4.9 </span>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star-half-o" aria-hidden="true"></i>
                                                    </div>
                                                    <p>Too much good experience with hospitality, cleanliness, facility and privacy and good value for money... To keep mind relaxing... Keep it up...</p>
                                                </div>
                                            </div>
                                        </OwlCarousel>
                                        <div className="hp-section">
                                            <div className="hp-amini detai-2p">
                                                {HtmlParser(description)}
                                            </div>
                                        </div>
                                        <div className="hp-section mb-3">
                                            <div className="hp-sub-tit">
                                                <h4><span>Aminitiese</span> Room</h4>
                                                <p>Aliquam id tempor sem. Cras molestie risus et lobortis congue. Donec id est consectetur, cursus tellus at, mattis lacus.</p>
                                            </div>
                                            <div className="room-amini overflow-hidden">
                                                <ul className='overflow-hidden p-0'>
                                                    {room.amenities && room.amenities.length > 0 ? (
                                                        room.amenities.map((amenity, index) => (
                                                            <li key={index}>
                                                                <img
                                                                    src={amenity.thumbnail || "/assets/images/icon/a2.png"}
                                                                    alt={amenity.name}
                                                                />
                                                                {amenity.name}
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <></>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="sidebar_widget">
                                            <div className='sidebar_booking_widget'>
                                                <p className='room_price'>
                                                    {room.price_per_months ? (
                                                        <strong>৳{room.price_per_months}</strong>
                                                    ) : (
                                                        <strong>৳{room.price_per_night}</strong>
                                                    )}
                                                    <span className='text-muted'>{room.price_per_months ? " per month" : " per night"}</span>
                                                </p>
                                                <div className='booking_widget_form mb-3'>

                                                    <div className="dates" ref={pickerRef}>
                                                        <div className="date" onClick={togglePicker}>
                                                            <p><strong>Check In</strong></p>
                                                            <p>{dateRange[0] ? dateRange[0].toLocaleDateString() : 'Add Date'}</p>
                                                        </div>
                                                        <div className="date" onClick={togglePicker}>
                                                            <p><strong>Check Out</strong></p>
                                                            <p>{dateRange[1] ? dateRange[1].toLocaleDateString() : 'Add Date'}</p>
                                                        </div>
                                                        <div className='daterangePicker'>
                                                            {open && (
                                                                <DateRangePicker
                                                                    value={dateRange}
                                                                    onChange={handleDateChange}
                                                                    open={open}
                                                                    onClose={() => setOpen(false)}
                                                                    placement="bottomStart"
                                                                    label={false}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className='guest'>
                                                        <div ref={toggleButtonRef} onClick={toggleOverlay}>
                                                            <p><strong>Guest</strong></p>
                                                            <p>
                                                                {adultCount > 0 && <span>{adultCount} Adult{adultCount > 1 ? 's' : ''} </span>}
                                                                {childrenCount > 0 && <span>{childrenCount} Child{childrenCount > 1 ? 'ren' : ''} </span>}
                                                                {roomCount > 0 && <span>{roomCount} Room{roomCount > 1 ? 's' : ''} </span>}

                                                                {(adultCount === 0 && childrenCount === 0 && roomCount === 0) && (
                                                                    <span>Add Guest</span>
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div ref={overlayRef} className={`input_overlay ${isOverlayOpen ? 'open' : ''}`}>
                                                            <div className='guest_counter'>
                                                                <div className='pull-left'>
                                                                    <strong>Adults</strong>
                                                                    <br />
                                                                    <span>Age 13+</span>
                                                                </div>
                                                                <div className='input_counter pull-right'>
                                                                    <button onClick={() => decrement(setAdultCount, adultCount)}>-</button>
                                                                    <strong className='counter'>{adultCount}</strong>
                                                                    <button onClick={() => increment(setAdultCount, adultCount)}>+</button>
                                                                </div>
                                                            </div>
                                                            <div className='guest_counter'>
                                                                <div className='pull-left'>
                                                                    <strong>Children</strong>
                                                                    <br />
                                                                    <span>Age 0-5</span>
                                                                </div>
                                                                <div className='input_counter pull-right'>
                                                                    <button onClick={() => decrement(setChildrenCount, childrenCount)}>-</button>
                                                                    <strong className='counter'>{childrenCount}</strong>
                                                                    <button onClick={() => increment(setChildrenCount, childrenCount)}>+</button>
                                                                </div>
                                                            </div>
                                                            <div className='guest_counter mb-0'>
                                                                <div className='pull-left'>
                                                                    <strong>Room</strong>
                                                                    <br />
                                                                    <span>Minimum 1+</span>
                                                                </div>
                                                                <div className='input_counter pull-right'>
                                                                    <button onClick={() => decrement(setRoomCount, roomCount)}>-</button>
                                                                    <strong className='counter'>{roomCount}</strong>
                                                                    <button onClick={() => increment(setRoomCount, roomCount)}>+</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type='submit' className='btn btn-lg btn-block btn-success mb-4' onClick={handleReserveClick}>Reserve</button>
                                                <p className='text-center mb-0'>You won't be charged yet</p>
                                            </div>
                                        </div>

                                        <div className="sidebar_widget">
                                            <div className="hp-call-in text-center">
                                                <img src="/younic-logo.png" alt="" />
                                                <h3><span>Check Availability. Call us!</span></h3>
                                                <strong className='mb-0'>+88 01325-05 19 15</strong> <br />
                                                <strong>+88 01711-70 47 29</strong> <br /><br />
                                                <small>We are available 24/7 Monday to Sunday</small>
                                                <a href="#">Call Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid pad-bot-40'>
                <div className="col-sm-8 mx-auto pl-5 pr-5">
                        <div className="contact-map" style={{'height':'500px'}}>
                            <iframe title='Younic Home' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7303.948859349035!2d90.3701136949866!3d23.748291235068937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755bf00724da8b9%3A0x82cd207efebe07ac!2sYounic%20Home!5e0!3m2!1sen!2sbd!4v1731401256947!5m2!1sen!2sbd" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faq_section">
                <div className="container">
                    <div className="faq-title">
                        <h2>F.A.Q</h2>
                        <p>Dolor sit amet consectetur adipiscing elit</p>
                    </div>
                    <ul className="faq-list">
                        <li data-aos="fade-up" data-aos-delay="100" className="aos-init aos-animate">
                            <a data-toggle="collapse" className="collapsed" href="#faq1" aria-expanded="false">Non consectetur a erat nam at lectus urna duis? <i className="fa fa-arrow-up"></i></a>
                            <div id="faq1" className="collapse" data-parent=".faq-list">
                                <p>
                                    Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.
                                </p>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="200" className="aos-init aos-animate">
                            <a data-toggle="collapse" href="#faq2" className="collapsed">Feugiat scelerisque varius morbi enim nunc faucibus a pellentesque? <i className="fa fa-arrow-up"></i></a>
                            <div id="faq2" className="collapse" data-parent=".faq-list">
                                <p>
                                    Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui.
                                </p>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="300" className="aos-init aos-animate">
                            <a data-toggle="collapse" href="#faq3" className="collapsed">Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi? <i className="fa fa-arrow-up"></i></a>
                            <div id="faq3" className="collapse" data-parent=".faq-list">
                                <p>
                                    Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi quis
                                </p>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="400" className="aos-init aos-animate">
                            <a data-toggle="collapse" href="#faq4" className="collapsed">Ac odio tempor orci dapibus. Aliquam eleifend mi in nulla? <i className="fa fa-arrow-up"></i></a>
                            <div id="faq4" className="collapse" data-parent=".faq-list">
                                <p>
                                    Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui.
                                </p>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="500" className="aos-init aos-animate">
                            <a data-toggle="collapse" href="#faq5" className="collapsed">Tempus quam pellentesque nec nam aliquam sem et tortor consequat? <i className="fa fa-arrow-up"></i></a>
                            <div id="faq5" className="collapse" data-parent=".faq-list">
                                <p>
                                    Molestie a iaculis at erat pellentesque adipiscing commodo. Dignissim suspendisse in est ante in. Nunc vel risus commodo viverra maecenas accumsan. Sit amet nisl suscipit adipiscing bibendum est. Purus gravida quis blandit turpis cursus in
                                </p>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="600" className="aos-init aos-animate">
                            <a data-toggle="collapse" href="#faq6" className="collapsed">Tortor vitae purus faucibus ornare. Varius vel pharetra vel turpis nunc eget lorem dolor? <i className="fa fa-arrow-up"></i></a>
                            <div id="faq6" className="collapse" data-parent=".faq-list">
                                <p>
                                    Laoreet sit amet cursus sit amet dictum sit amet justo. Mauris vitae ultricies leo integer malesuada nunc vel. Tincidunt eget nullam non nisi est sit amet. Turpis nunc eget lorem dolor sed. Ut venenatis tellus in metus vulputate eu scelerisque. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. Nibh tellus molestie nunc non blandit massa enim nec.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>

        </>
    );
}

export default WithRouter(RoomDetails);
