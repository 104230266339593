import React from 'react';
import AppRoute from './routes/AppRoute';
import './_utility/AxiosConfig';

function App() {
	return (
		<>
			<AppRoute />

			<section>
				<div className="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="loginModalLabel" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-body p-0">
								<div className="log-in-pop-left">
									<h1>Hello...</h1>
									<p>Don't have an account? Create your account. It's take less then a minutes</p>
									<h4>Login with social media</h4>
									<ul className='list-unstyled'>
										<li><a href="#"><i className="fa fa-facebook"></i> Facebook</a>
										</li>
										<li><a href="#"><i className="fa fa-google"></i> Google+</a>
										</li>
										<li><a href="#"><i className="fa fa-twitter"></i> Twitter</a>
										</li>
									</ul>
								</div>
								<div className="log-in-pop-right">
									<a href="#" className="pop-close" data-dismiss="modal"><img style={{ width: '15px' }} src="/assets/images/cancel.png" alt="" />
									</a>
									<h4>Login</h4>
									<p>Don't have an account? Create your account. It's take less then a minutes</p>
									<form>
										<div className='form-group'>
											<div className="input-field">
												<input type="text" id="login_name" className="form-control form-control-lg" />
												<label htmlFor='login_name'>User name</label>
											</div>
										</div>
										<div className='form-group'>
											<div className="input-field">
												<input type="password" id='login_password' className="form-control form-control-lg" />
												<label htmlFor='login_password'>Password</label>
											</div>
										</div>
										<div className='form-group'>
											<div className="log-ch-bx">
											<input type="checkbox" id="remember_me" className='float-left mr-2' />
											<label htmlFor="remember_me" className='pt-2'>Remember me</label>
											</div>
										</div>
										<div>
											<div className="input-field">
												<input type="submit" value="Login" className="waves-effect waves-light log-in-btn" />
											</div>
										</div>
										<div>
											<div className="input-field"> <a href="#" data-dismiss="modal" data-toggle="modal"
												data-target="#forgotPasswordModal">Forgot password</a> | <a href="#" data-dismiss="modal"
													data-toggle="modal" data-target="#registerModal">Create a new account</a> </div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="modal fade" id="registerModal" tabindex="-1" role="dialog" aria-labelledby="registerModalLabel" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-body p-0">
								<div className="log-in-pop-left">
									<h1>Hello...</h1>
									<p>Don't have an account? Create your account. It's take less then a minutes</p>
									<h4>Login with social media</h4>
									<ul className='list-unstyled'>
										<li><a href="#"><i className="fa fa-facebook"></i> Facebook</a>
										</li>
										<li><a href="#"><i className="fa fa-google"></i> Google+</a>
										</li>
										<li><a href="#"><i className="fa fa-twitter"></i> Twitter</a>
										</li>
									</ul>
								</div>
								<div className="log-in-pop-right">
								<a href="#" className="pop-close" data-dismiss="modal"><img style={{ width: '15px' }} src="/assets/images/cancel.png" alt="" />
								</a>
									<h4>Create an Account</h4>
									<p>Don't have an account? Create your account. It's take less then a minutes</p>
									<form>
										<div className='form-group'>
											<div className="input-field">
												<input type="text" id="register_name" name='name' className="form-control form-control-lg" />
												<label htmlFor='register_name'>User name</label>
											</div>
										</div>
										<div className='form-group'>
											<div className="input-field">
												<input type="email" id='register_email' name='email' className="form-control form-control-lg" />
												<label htmlFor='register_email'>Email id</label>
											</div>
										</div>
										<div className='form-group'>
											<div className="input-field">
												<input type="password" id='register_password' name='password' className="form-control form-control-lg" />
												<label htmlFor='register_password'>Password</label>
											</div>
										</div>
										<div className='form-group'>
											<div className="input-field">
												<input type="password" id='confirm-password' name='confirm-password' className="form-control form-control-lg" />
												<label htmlFor='confirm-password'>Confirm password</label>
											</div>
										</div>
										<div className='form-group'>
											<div className="input-field">
												<input type="submit" value="Register" className="btn btn-danger" />
											</div>
										</div>
										<div className='form-group'>
											<div className="input-field"> <a href="#" data-dismiss="modal" data-toggle="modal"
												data-target="#loginModal">Are you a already member ? Login</a> </div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="modal fade" id="forgotPasswordModal" tabindex="-1" role="dialog" aria-labelledby="forgotPasswordModalLabel" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-body p-0">
								<div className="log-in-pop-left">
									<h1>Hello...</h1>
									<p>Don't have an account? Create your account. It's take less then a minutes</p>
									<h4>Login with social media</h4>
									<ul className='list-unstyled'>
										<li><a href="#"><i className="fa fa-facebook"></i> Facebook</a>
										</li>
										<li><a href="#"><i className="fa fa-google"></i> Google+</a>
										</li>
										<li><a href="#"><i className="fa fa-twitter"></i> Twitter</a>
										</li>
									</ul>
								</div>
								<div className="log-in-pop-right">
									<a href="#" className="pop-close" data-dismiss="modal"><img style={{ width: '15px' }} src="/assets/images/cancel.png" alt="" />
									</a>
									<h4>Forgot password</h4>
									<p>Don't have an account? Create your account. It's take less then a minutes</p>
									<form>
										<div className='form-group'>
											<div className="input-field">
												<input type="text" name='username' id="forget_username" className="form-control form-control-lg" />
												<label htmlFor='forget_username'>User name or email id</label>
											</div>
										</div>
										<div className='form-group'>
											<div className="input-field">
												<input type="submit" value="Submit" className="waves-effect waves-light log-in-btn" />
											</div>
										</div>
										<div className='form-group'>
											<div className="input-field s12"> <a href="#" data-dismiss="modal" data-toggle="modal"
												data-target="#loginModal">Are you a already member ? Login</a> | <a href="#"
													data-dismiss="modal" data-toggle="modal" data-target="#registerModal">Create a new
													account</a> </div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default App;
