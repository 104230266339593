// DateRangeSelector.js
import React, { useState, useRef } from 'react';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

const DateRangeSelector = ({ dateRange, setDateRange }) => {
    const [open, setOpen] = useState(false);
    const pickerRef = useRef(null);

    const togglePicker = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleDateChange = (value) => {
        setDateRange(value);
        setOpen(false); // Close the picker after selecting dates
    };

    return (
        <div className="d-flex align-items-center pl-0 search_dates" ref={pickerRef}>
            <input
                onClick={togglePicker}
                type="text"
                className="form-control search_date_select search_check_in border-0 p-0"
                placeholder="Check in"
                value={dateRange[0] ? dateRange[0].toLocaleDateString() : ''}
                readOnly
            />
            <div className="border-left mx-2" style={{ height: "30px" }}></div>
            <input
                onClick={togglePicker}
                type="text"
                className="form-control search_date_select search_check_out border-0 p-0"
                placeholder="Check out"
                value={dateRange[1] ? dateRange[1].toLocaleDateString() : ''}
                readOnly
            />

            <div className='daterangePicker'>
                {open && (
                    <DateRangePicker
                        value={dateRange}
                        onChange={handleDateChange}
                        open={open}
                        onClose={() => setOpen(false)}
                        placement="bottomStart"
                    />
                )}
            </div>
        </div>
    );
};

export default DateRangeSelector;