import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SearchForm from '../partials/SearchForm';

class Header extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <header>
                    <nav className="navbar navbar-light bg-light fixed-top">
                        <div className="container-fluid">
                            <div className="col-sm-11 mx-auto">
                                <div className="d-flex justify-content-between align-items-center w-100">
                                    <div className="navbar-header">
                                        <Link to="/" className="navbar-brand logo">
                                            <img src="/assets/images/logo1.png" alt="Logo" />
                                        </Link>
                                    </div>
                                    <div className="search_panel">
                                        <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="permanent-tab" data-toggle="tab" href="#permanent"
                                                    role="tab" aria-controls="permanent" aria-selected="true">Permanent</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="guest-tab" data-toggle="tab" href="#guest" role="tab"
                                                    aria-controls="guest" aria-selected="false">Guest</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <ul className="nav navbar-nav">
                                        <li className="nav-item dropdown">
                                            <a href="#" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="fa fa-bars mr-1" aria-hidden="true"></i>
                                                <img src="/assets/images/profile.png" width="30px" alt="Profile" />
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <Link className="dropdown-item" to='/dashboard'>My Account</Link>
                                                <a className="dropdown-item" href="#" data-toggle="modal" data-target="#registerModal">Register</a>
                                                <a className="dropdown-item" href="#" data-toggle="modal" data-target="#loginModal">Log In</a>
                                                <div className="dropdown-divider"></div>
                                                <Link className="dropdown-item" to='/'>Home</Link>
                                                <Link className="dropdown-item" to='/about-us'>About Us</Link>
                                                <Link to="/contact-us" className="dropdown-item">Contact Us</Link>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <SearchForm/>
                </header>
            </>
        );
    }
}

export default Header;
