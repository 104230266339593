import React from 'react';
import { Link } from 'react-router-dom';

const RoomBooking = () => {
    return (
        <>
            <section>
		<div class="inn-body-section">
			<div class="container mt-5">
				<div class="row">
				  <div class="col-sm-8">
					<div class="form-group">
						<a href="#"><h1> <i class="fa fa-chevron-left"></i> Request to book</h1></a>
					</div>

					<div class="panel-body">
						<div class="head-typo typo-com">
							<h4>Branch & Apartment Information</h4>
							<p><strong>Branch Name:</strong> Rajshahi Branch</p>
							<p><strong>Branch Address:</strong> Rajshahi</p>
							<p><strong>Apartment Name:</strong> VonRueden LLC</p>
							<p><strong>Apartment Address:</strong> Rajshahi</p>
							<p><strong>Contact Number:</strong> +880171115895</p>
						</div>

						<div class="head-typo typo-com">
							<h4>Choose how to pay</h4>
							<form action="#" class="ng-pristine ng-valid">
								<div class="inline">
									<input name="group1" type="radio" id="test1"/>
									<label for="test1">Bkash</label>
								</div>
								<div class="inline">
									<input name="group1" type="radio" id="test2"/>
									<label for="test2">Rocket</label>
								</div>
								<div class="inline">
									<input class="with-gap" name="group1" type="radio" id="test3"/>
									<label for="test3">Nagod</label>
								</div>
							</form>
						</div>

						<div class="head-typo typo-com">
							<h4>Log in or sign up to book</h4>
							<form class="s12">
								<div class="form-group">
									<p>Sign in to your account to continue, or <a href="#">Create Account</a></p>
								</div>
								<div class="form-group">
									<label for="usernameoremail">E-mail Address</label>
									<div class="input-group">
										<div class="input-group-addon">
											<i class="fa fa-user-secret" aria-hidden="true"></i>
										</div>
										<input placeholder="Enter Your E-mail Address" class="form-control" type="text" name="email" id="usernameoremail"/>
									<div data-lastpass-icon-root="" style={{"position": "relative !important; height: 0px !important; width: 0px !important; float: left !important"}}></div></div>
								</div>
								<div class="form-group">
									<div class="input-group input-password">
										<div class="input-group-addon">
											<i class="fa fa-key" aria-hidden="true"></i>
										</div>
										<input placeholder="Enter Your Password" class="form-control" type="password" name="password" id="password"/>
										
									</div>
								</div>
								<div>
									<div class="input-field s4">
										<button class="btn btn-success btn-block">Register</button>
									</div>
								</div>
								<br/>
								<p class="text-center">-- OR --</p>
								<div>
									<a href="#" class="btn btn-primary btn-block"><i class="fa fa-facebook"></i> Facebook</a>
									<br/>
									<a href="#" class="btn btn-danger btn-block"><i class="fa fa-google"></i> Google+</a>
								</div>

							</form>

							
						</div>
					</div>
				</div>
			  
				  <div class="col-sm-4">
					<div class="summary panel panel-default shadow">
					  <img src="/assets/images/room/4.jpg" alt="Room image" class="img-responsive center-block" style={{"width": "100%"}}/>
					  <div class="panel-body">
						<strong>Nice place for your stay in Paris</strong> <br/>
						<span>Rental unit - 4.96 (381 reviews)</span>
					  </div>
					</div>
			  
					<div class="price-details panel panel-default shadow">
					  <div class="panel-body">
						<p>$76.08 x 5 nights <span class="pull-right">$380.40</span></p>
						<p>Airbnb service fee <span class="pull-right">$58.04</span></p>
						<p>Taxes <span class="pull-right">$12.90</span></p>
						<div class="price-summary">
						  <p><strong>Total (USD)</strong> <span class="pull-right"><strong>$451.34</strong></span></p>
						</div>
					  </div>
					</div>
				  </div>
				</div>
			  </div>
			  
		</div>
	</section>
        </>
    );
}

export default RoomBooking;
