import React, {Component} from 'react';
import {Routes, Route, BrowserRouter} from "react-router-dom";
import Home from "../pages/Home";
import ContactUs from "../pages/ContactUs";
import AboutUs from "../pages/AboutUs";
import Dashboard from "../pages/dashboard/Dashboard";
import NotFound from "../pages/NotFound";
import Footer from '../component/common/Footer';
import Header from '../component/common/Header';
import RoomDetails from '../pages/rooms/RoomDetails';

import axios from "axios";
import SessionHelper from '../session/SessionHelper';
import RoomBooking from '../pages/rooms/RoomBooking';

class AppRoute extends Component {
    componentDidMount() {
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.get('/api/branches').then(res => {
                this.setState({branches:res.data.data});
                SessionHelper.SetBranchesSession(res.data.data);
            }).catch((error)=>{});
        });
    }

    render() {
        return (
            <>
                <BrowserRouter>
                    <Header/>
                    <Routes>
                        <Route path="*" element={<NotFound/>} />
                        <Route path="/" element={<Home />} />
                        <Route path="contact-us" element={<ContactUs />} />
                        <Route path="about-us" element={<AboutUs />} />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="/room/:room_id/details" element={<RoomDetails />} />
                        <Route path="/room/:room_id/booking" element={<RoomBooking />} />
                    </Routes>
                    <Footer/>
                </BrowserRouter>
            </>
        );
    }
}

export default AppRoute;
